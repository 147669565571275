/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 115번째 로그에서는 AWS 새 CEO, 일본 라인 해외 서버 저장 논란, Roam Research에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "AWS 새 CEO"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://m.post.naver.com/viewer/postView.nhn?volumeNo=31024352&memberNo=33837307"
  }, "새 AWS CEO를 맡은 아담 셀립스키는 누구인가? : 네이버 포스트")), "\n"), "\n", React.createElement(_components.h3, null, "일본 라인 해외 서버 저장 논란"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://biz.chosun.com/site/data/html_dir/2021/03/18/2021031801237.html"
  }, "日 ‘라인’ 개인정보 유출 논란…“해외사업에 다 걸었다”던 이해진 시험대")), "\n"), "\n", React.createElement(_components.h3, null, "앨런 튜링이 영국 지폐에 등장할 예정"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zdnet.co.kr/view/?no=20210326141425"
  }, "‘AI 선구자’ 앨런 튜링 새긴 50파운드 새 지폐 공개 - ZDNet Korea")), "\n"), "\n", React.createElement(_components.h3, null, "리처드 스톨만 FSF 복귀"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rms-open-letter.github.io/"
  }, "An open letter to remove Richard M. Stallman from all leadership positions")), "\n"), "\n", React.createElement(_components.h3, null, "서울시장 보권 선거 후보 홈페이지"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/raccoonyy/status/1376177040638976001"
  }, "서울시장 후보들의 공식 홈페이지를 살펴보니 - Twitter")), "\n"), "\n", React.createElement(_components.h3, null, "Roam Research 문서 도구"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://roamresearch.com/"
  }, "Roam Research – A note taking tool for networked thought.")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
